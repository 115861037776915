<template>
  <div class="h-full flex flex-col gap-2">
    <div class="px-4 pt-2">
      <div class="flex gap-5 justify-between items-center">
        <div class="flex px-2">
          <fw-heading size="h3" :counter="maps ? maps.length : 0" :counter-total="totalResults">Pessoas</fw-heading>
        </div>
        <div class="flex gap-5 items-center">
          <div>
            <ContextualSearch
              v-if="showSearch"
              :restore-filters="true"
              :loading="loading"
              type="minimal"
              :filter-options="filters"
              :applied-filters="appliedFilters"
              :applied-sort="orderByValue"
              :applied-sort-direction="orderDirection"
              :multifilter="multifilter"
              :start-value="searchInput"
              :start-period="startPeriod"
              :end-period="endPeriod"
              :order-by-options="orderBy"
              :show-time-period="activeView == 'table'"
              :time-period-label="'Período'"
              :can-close="activeModal === null"
              @sort-order-changed="sortOrderChanged"
              @search="search"
            />
          </div>
          <div>
            <b-dropdown aria-role="list" position="is-bottom-left">
              <fw-button-dropdown
                slot="trigger"
                aria-role="listitem"
                type="xlight"
                label="Vista"
                class="font-medium text-gray-500"
              >
                <fw-icon-list-row class="h-4 w-4" /> {{ activeView == 'list' ? 'Ver em lista' : 'Ver em tabela' }}
              </fw-button-dropdown>
              <b-dropdown-item label="list" paddingless>
                <fw-button type="dropdown-item" @click.native="changeView('list')">Ver em lista </fw-button>
              </b-dropdown-item>
              <b-dropdown-item label="table" paddingless>
                <fw-button type="dropdown-item" @click.native="changeView('table')">Ver em tabela</fw-button>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>

    <div class="flex-1 px-4 flex flex-col" :class="{ 'overflow-auto': activeView == 'list' }">
      <BlockYearActivity
        v-if="!loadingStats"
        class="border-b mb-4 flex-shrink-0"
        :data="yearActivity"
        :year="map.year"
      />
      <LoadingPlaceholder v-if="loading" />

      <fw-panel-info v-if="!loading && (!maps || !maps.length)" clean empty>
        {{ $t('nomaps') }}
      </fw-panel-info>

      <div v-else-if="!loading && activeView == 'list'" class="bg-white rounded-lg">
        <div v-for="item in maps" :key="item.key" class="p-1 border-b border-gray-100">
          <RecordManageMapUser
            :item="item"
            :user="users[item.user_key] ?? { full_name: item.user_key, key: item.user_key }"
          />
        </div>
      </div>

      <div v-else-if="!loading && activeView == 'table'" ref="tableParent" class="flex-1">
        <PanelStickyTable
          :year="map.year"
          :user-maps="maps"
          :users="users"
          :start-date="startPeriod"
          :end-date="endPeriod"
          :height="tableHeight"
          :table-parent-ref-name="'tableParent'"
        />
      </div>
    </div>

    <BlockPagination
      v-if="totalPages > 1 && !loading"
      :per-page="limit"
      :total="totalResults"
      :total-pages="totalPages"
      :current.sync="page"
      @page-changed="pageChanged"
    />
  </div>
</template>

<script>
import ContextualSearch from '@/fw-modules/fw-core-vue/ui/components/search/ContextualSearch'
import BlockYearActivity from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockYearActivity'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import RecordManageMapUser from '@/components/records/manage/RecordManageMapUser'
import PanelStickyTable from '@/components/panels/manage/PanelStickyTable'
import { MAP_USER_STATUS } from '@/utils/index.js'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'

export default {
  components: {
    ContextualSearch,
    BlockPagination,
    LoadingPlaceholder,
    RecordManageMapUser,
    PanelStickyTable,
    BlockYearActivity
  },
  props: {
    showFilters: {
      type: Boolean,
      default: true
    },
    showSearch: {
      type: Boolean,
      default: true
    },
    multifilter: {
      type: Boolean,
      default: true
    },
    maxNumber: {
      type: Number,
      default: null
    },
    map: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      status: Object.freeze(MAP_USER_STATUS),
      filterByType: 'all',
      orderBy: [
        {
          key: 'user_name',
          label: 'Nome',
          type: 'string'
        }
      ],
      searchInput: '',
      orderByValue: 'user_name',
      orderDirection: 'ASC',
      appliedFilters: [],
      startPeriod: null,
      endPeriod: null,
      page: 1,
      totalResults: 0,
      totalPages: 1,
      limit: 50,
      maps: [],
      count: {},
      users: {},
      loading: true,
      loadingStats: true,

      activeView: 'list',
      activeModal: null,

      tableDays: [],
      tableMonths: [],

      tableHeight: null
    }
  },

  computed: {
    user() {
      return this.$store.getters.getUser
    },
    api() {
      return this.$store.state.api.base
    },

    language() {
      return this.$store.state.language ?? 'pt'
    },

    yearActivity() {
      let days = {}

      for (const [day, count] of Object.entries(this.count?.vacation_days_count)) {
        days[Dates.format(day, 'DD/MM/YYYY')] = count
      }

      return days
    },

    filters() {
      return [
        {
          key: 'status',
          label: 'Estado',
          options: [
            {
              key: 'reset',
              label: 'Tudo'
            },
            {
              key: 'draft',
              label: this.status.draft[this.language]
            },
            {
              key: 'submitted',
              label: this.status.submitted[this.language]
            },
            {
              key: 'canceled',
              label: this.status.canceled[this.language]
            },
            {
              key: 'approved',
              label: this.status.approved[this.language]
            },
            {
              key: 'declined',
              label: this.status.declined[this.language]
            },
            {
              key: 'pre_approved',
              label: this.status.pre_approved[this.language]
            },
            {
              key: 'outdated',
              label: this.status.outdated[this.language]
            }
          ]
        }
      ]
    }
  },

  mounted() {
    this.getUrlParams()
    // this.getMaps()
    this.loadStats()
  },

  methods: {
    async loadStats() {
      this.loadingStats = true
      await utils.tryAndCatch(this, async () => {
        const res = await this.api.getManagerMapStats(this.map.key)
        console.log('getManagerMapStats', res.count)
        this.count = res.count
      })

      this.loadingStats = false
    },

    changeView(view) {
      this.tableHeight = null
      this.activeView = view
      this.renderTable()
    },

    renderTable() {
      console.log('renderTable', this.activeView)
      if (this.activeView === 'table') {
        this.$nextTick(() => {
          console.log('tableParent', this.$refs['tableParent'].offsetTop)
          this.tableHeight = window.innerHeight - this.$refs['tableParent'].offsetTop + 'px'
        })
      }
    },

    deleteFilter(index) {
      this.appliedFilters.splice(index, 1)
      this.setUrlParams()
      this.getMaps()
    },

    deleteDates() {
      this.startPeriod = null
      this.endPeriod = null
      this.setUrlParams()
      this.getMaps()
    },

    getFilterText(key) {
      return utils.getFilterText(key, this.filters)
    },

    search(data) {
      console.log('search', data)
      this.appliedFilters = JSON.parse(JSON.stringify(data.filters))
      this.searchInput = data.term

      if (data.orderBy != null) {
        this.orderByValue = data.orderBy
        this.orderDirection = data.orderDirection
      }

      this.startPeriod = data.dates.length == 2 ? this.$options.filters.formatDate(data.dates[0]) : null
      this.endPeriod = data.dates.length == 2 ? this.$options.filters.formatDate(data.dates[1]) : null

      this.$emit('searching')

      this.setUrlParams()
      this.getMaps()
    },

    getUrlParams() {
      if (this.$route.query.q) {
        this.searchInput = this.$route.query.q
      }

      if (this.$route.query.f) {
        this.appliedFilters = this.$route.query.f.split(',')
      }

      if (this.$route.query.s) {
        this.orderByValue = this.$route.query.s
        this.orderDirection =
          this.$route.query.o == 'ASC' || this.$route.query.o == 'DESC' ? this.$route.query.o : 'none'
      }

      if (this.$route.query.p) {
        this.page = parseInt(this.$route.query.p)
      }

      if (this.$route.query.start) {
        this.startPeriod = this.$route.query.start
      }

      if (this.$route.query.end) {
        this.endPeriod = this.$route.query.end
      }
    },

    setUrlParams() {
      let query = {}
      if (this.searchInput.length > 0) {
        query['query'] = this.searchInput
      }

      if (this.appliedFilters.length > 0) {
        query['f'] = this.appliedFilters.join(',')
      }

      if (this.orderByValue.length > 0) {
        query['s'] = this.orderByValue
        query['o'] = this.orderDirection
      }

      if (this.startPeriod != null && this.endPeriod != null) {
        query['start'] = this.startPeriod
        query['end'] = this.endPeriod
      }

      query['p'] = this.page

      this.$router.push({ path: this.$route.path, query: query })
    },

    sortOrderChanged(newSort) {
      if (newSort != null && newSort.key != null) {
        this.orderByValue = newSort.key.key
        this.orderDirection = newSort.direction
      }
      this.setUrlParams()
      this.getMaps()
    },

    async getMaps() {
      this.loading = true
      const maxNumber = this.maxNumber != null ? this.maxNumber : this.limit
      const query = {
        latest_only: true,
        limit: maxNumber,
        page: this.page,
        ...utils.setFiltersQuery(this.appliedFilters)
      }
      console.log('query', query)
      if (this.orderByValue.length > 0) {
        query['sort'] = this.orderByValue
        query['direction'] = this.orderDirection.toLowerCase()
      }

      if (this.searchInput.length > 0) {
        query['query'] = this.searchInput
      }

      if (this.startPeriod && this.endPeriod) {
        query['created_start'] = this.parseDatesForPayload(this.startPeriod)
        query['created_end'] = this.parseDatesForPayload(this.endPeriod)
      }

      try {
        const result = await this.api.getManagerMapUsers(this.map.key, query)
        console.log('getMaps :>> ', result)
        this.totalResults = result.pagination?.total_items
        this.totalPages = result.pagination?.total_pages
        this.page = result.pagination?.current_page
        this.maps = result.user_maps
        this.users = result.users
        this.renderTable()
      } catch (error) {
        console.error('getMaps Error: ', error)
      }

      this.loading = false
    },

    pageChanged(page) {
      console.log('pageChanged to :>> ', page)
      if (page) this.page = page
      this.setUrlParams()
      this.getMaps()
    },

    parseDatesForPayload(formatedDate) {
      if (formatedDate) {
        return Dates.from(formatedDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
      }
      return formatedDate
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "createdDate": "Data de criação",
    "results": "Resultados",
    "loadMoreData": "Ver mais resultados",
    "nomaps": "Sem mapas a mostrar.",
    "spaceType": {
      "label": "Tipo de espaço",
      "development": "Desenvolvimento",
      "support": "Suporte"
    },
    "all": "Todos",
    "orderBy": {
      "shortName": "Nome do espaço",
      "spaceStatus": "Estado do espaço",
      "createdDate": "Data de criação",
      "spaceType": "Tipo de espaço",
      "title": "Titulo do espaço",
      "prefix": "Prefixo do espaço",
      "endDate": "Data de fim",
      "startDate": "Data de início"
    },
    "mapStatus": {
      "label": "Estado do procedimento",
      "canceled": "Cancelado",
      "published": "Publicado",
      "draft": "Rascunho"
    },
    "mapType": {
      "label": "Tipo de procedimento",
      "scholarship": "Apoio",
      "occupational_medicine": "Medicina do Trabalho",
      "internship": "Estágio",
      "job": "Emprego",
      "other": "Outro"
    }
  },
  "en": {
    "createdDate": "Creation date",
    "results": "Results",
    "nomaps": "No maps to present.",
    "loadMoreData": "Load more data",
    "spaceType": {
      "label": "Space type",
      "development": "Development",
      "support": "Support",
      "other": "Other"
    },
    "all": "All",
    "orderBy": {
      "shortName": "Space short name",
      "spaceStatus": "Space status",
      "createdDate": "Creation date",
      "spaceType": "Space type",
      "title": "Space title",
      "prefix": "Space prefix",
      "endDate": "End date",
      "startDate": "Start date"
    },
    "mapStatus": {
      "label": "map status",
      "canceled": "Cancelado",
      "published": "Published",
      "draft": "Draft"
    },
    "mapType": {
      "label": "map type",
      "scholarship": "Scholarship",
      "occupational_medicine": "Occupational Medicine",
      "internship": "Internship",
      "job": "Job",
      "other": "Other"
    }
  }
}
</i18n>
